import gql from 'graphql-tag';

export default gql`
query getAllGroupsWithPersonalization {
  groups(where: {groups_privileges: {_and: {privilege: {handle: {_eq: "can_use_personalization"}}, value: {_cast: {String: {_eq: "true"}}}}}}) {
    group_id
    name
    root_folder_id
    slug
    domain
    group_path
    parent_group_id
    settings
  }
}
`;
