var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-button",
        {
          staticClass: "green-button-transparent border-0",
          on: {
            click: function($event) {
              return _vm.$bvModal.show("modal-add-item")
            }
          }
        },
        [
          _c("b-icon", {
            staticClass: "mr-2 top--3",
            attrs: {
              icon: "plus-circle-fill",
              "aria-hidden": "true",
              scale: "1"
            }
          }),
          _vm._v(" " + _vm._s(_vm.$t("buttons.addItem")) + " ")
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "modal-add-item",
          attrs: {
            id: "modal-add-item",
            centered: "",
            "no-close-on-backdrop": "",
            "footer-class": "flex-nowrap"
          },
          on: { hidden: _vm.resetModal },
          scopedSlots: _vm._u([
            {
              key: "modal-header",
              fn: function() {
                return [
                  _c("h3", [_vm._v(_vm._s(_vm.$t("modals.addItem.title")))])
                ]
              },
              proxy: true
            },
            {
              key: "modal-footer",
              fn: function({ close }) {
                return [
                  _c(
                    "b-button",
                    {
                      staticClass: "p-3 border-right-light-grey",
                      attrs: { block: "" },
                      on: {
                        click: function($event) {
                          return close()
                        }
                      }
                    },
                    [_vm._v(" " + _vm._s(_vm.$t("buttons.cancel")) + " ")]
                  ),
                  _c(
                    "b-button",
                    {
                      staticClass: "p-3",
                      attrs: {
                        variant: "primary",
                        block: "",
                        disabled: _vm.buttonIsDisabled
                      },
                      on: { click: _vm.addItem }
                    },
                    [
                      _vm._v(
                        " " + _vm._s(_vm.$t("modals.addItem.validate")) + " "
                      )
                    ]
                  )
                ]
              }
            }
          ])
        },
        [
          _c(
            "div",
            { staticClass: "p-5" },
            [
              _c("p", [_vm._v(_vm._s(_vm.$t("modals.addItem.message")))]),
              _c("treeselect", {
                attrs: {
                  multiple: true,
                  flat: true,
                  options: _vm.allGroupsOfLoggedInUser,
                  loadOptions: _vm.loadOptions,
                  clearable: false,
                  placeholder: "Select an item",
                  noChildrenText: _vm.$t("modals.move.noChildren")
                },
                on: { select: _vm.selectItem, deselect: _vm.deselectItem },
                scopedSlots: _vm._u([
                  {
                    key: "value-label",
                    fn: function({ node }) {
                      return _c("div", {}, [_vm._v(_vm._s(node.raw.label))])
                    }
                  }
                ]),
                model: {
                  value: _vm.treeItems,
                  callback: function($$v) {
                    _vm.treeItems = $$v
                  },
                  expression: "treeItems"
                }
              })
            ],
            1
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }